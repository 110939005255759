import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import GlobalStyles from '~styles/global'
import Header from '~components/Header'
import Cart from '~components/Cart'
import Footer from '~components/Footer'
import smoothscroll from 'smoothscroll-polyfill'
import { mobile, tablet } from '~styles/global'
import Transition from '~components/Transition'
import { useSiteState, useCart } from '../context/siteContext'
import styled from '@emotion/styled'
import { useLocation } from '@reach/router'
import '~styles/static.css'
import { motion, AnimatePresence } from 'framer-motion'

const Layout = ({ children }) => {

	const [siteState, setSiteState] = useSiteState()
	const { closeCart } = useCart()
	const location = useLocation()
  
	useEffect(() => {
		smoothscroll.polyfill()
	}, [])

	const isProjectPage = location.pathname.includes('project/') || location.pathname === '/'

	// Close the cart whenever the route changes
	useEffect(() => {
		closeCart()
		// eslint-disable-next-line
		if(!isProjectPage){
			window.setTimeout(() => {
				setSiteState({...siteState, projectTitle: null})
			}, 0)
		}
	}, [location])

	return (
		<>
			<GlobalStyles />
			<Header />
			<Cart open={siteState.cartOpen} />
			<GoldFade />
			<div css={css`
				transition: opacity 1s;
				opacity: ${siteState.loading ? 0 : 1};
			`}>
				<AnimatePresence>
					{siteState.projectTitle &&
						<ProjectTitle 
							key={siteState.projectTitle}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.4}}
							className="project-title"
							css={css`
							position: ${siteState.projectTitleSticky ? 'sticky' : 'fixed'};
							top: ${siteState.projectTitleSticky ? 'calc(-50vh + 60px)' : '0'};
							margin-bottom: ${siteState.projectTitleSticky ? '-50vh' : '0'};
							transform: ${siteState.projectTitleSticky ? 'translateY(50vh)' : '0'};
						`}>
							<motion.h2 css={css`transition: opacity 0.3s;`}>
								<Link to={`project/${siteState.projectTitle[2]}`} css={css`
									pointer-events: ${siteState.projectTitleSticky ? 'none' : 'all'};
								`}>
									{siteState.projectTitle[0]}
									{siteState.projectTitle[1] && 
										<>
											<br/>
											{siteState.projectTitle[1]}
										</>
									}
								</Link>
							</motion.h2>
						</ProjectTitle>	
					}
				</AnimatePresence>			
				<main>
					<Transition>
						<div css={css`
							min-height: calc(100vh);
							display: flex;
							flex-direction: column;
							${mobile}{
								min-height: calc(100vh);
							}
						`}>
							{children}
							<Footer css={css`margin-top: auto;`}/>
						</div>
					</Transition>
				</main>
			</div>
			<Overlay 
				onClick={() => setSiteState(prevState => ({
					...prevState,
					cartOpen: false,
				}))}
				css={css`
          pointer-events: ${siteState.cartOpen? 'all' : 'none'};
      `}/>
		</>
	)
}

const ProjectTitle = styled(motion.div)`
	position: fixed;
	top: 0;
	margin-top: 50vh;
	transform: translateY(-50%);
	margin-left: 20px;
	pointer-events: none;
	width: 100%;
	z-index: 10;
	max-width: 90vw;
	/* margin-bottom: -50vh; */
	h2{
		position: absolute;
		top: 0;
		width: 100%;
	}
	/* a{
		pointer-events: all;
	} */
	${tablet}{
		display: none;
	}
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const GoldFade = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: var(--xxl);
	z-index: 11;
	overflow: visible;
	mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
	/* backdrop-filter: blur(10px); */
	background: var(--olive);
	transition: background-color 1.3s;

`

Layout.propTypes = {
	children: PropTypes.node,
}

export default Layout

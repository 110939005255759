import React from 'react'
import { Global, css } from '@emotion/react'
import Section from '~components/Section'
import styled from '@emotion/styled'

export const breakpoints =  {
	mobile: '@media (max-width: 768px)',
	tablet: '@media (max-width: 1024px)',
	desktop: '@media (max-width: 1230px)',
	desktopUp: '@media (min-width: 1025px)'
}

export const mobile = breakpoints.mobile
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp


const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`
  body {
    font-family: "Arizona", serif;
		font-size: 18px;
  }

  :root{
    --white: #ffffff;
    --black: #1C1C1C;
		--olive: #847747;
		--olivetrans: rgba(132, 119, 71, 0);
    --xxs: 5px;
    --xs: 10px;
    --s: 20px;
    --m: 30px;
    --l: 40px;
    --xl: 50px;
    --xxl: 80px;
    --xxxl: 100px;
    --xxxxl: 150px;
		--xxxxxl: 250px;
  }

  ${tablet}{
    :root{
      --xxs: 3px;
      --xs: 7px;
      --s: 15px;
      --m: 20px;
      --l: 30px;
      --xl: 35px;
      --xxl: 40px;
      --xxxl: 60px;
      --xxxxl: 100px;
			--xxxxxl: 100px;
    }
  }

  p{
    margin-bottom: 0.5em;
    &:last-child{
      margin-bottom: 0;
    }
		a{
			border-bottom: 1px solid;
			transition: border-color 0.35s;
			&:hover{
				border-bottom: 1px solid transparent;
			}
		}
  }

  h1,
  .h1 {
    font-size: 80px;
    line-height: 80px;
    ${tablet} {
      font-size: 40px;
      line-height: 40px;
    }
  }

	h2,
  .h2 {
    font-size: 40px;
    line-height: 1;
		text-transform: uppercase;
		font-family: 'Sohne', sans-serif;
    ${tablet} {
      font-size: 24px;
    }
  }

	h3,
  .h3 {
    font-size: 40px;
    line-height: 1;
		text-transform: uppercase;
		${tablet}{
			font-size: 28px;
		}
  }

	h4,
  .h4 {
    font-size: 20px;
    line-height: 1.2;
		text-transform: uppercase;
		${tablet}{
			font-size: 13px;
		}
  }

	h5,
  .h5 {
    font-size: 18px;
    line-height: 1.2;
		text-transform: uppercase;
		${tablet}{
			font-size: 14px;
		}
  }

	h6,
  .h6 {
    font-size: 22px;
    line-height: 1;
		text-transform: uppercase;
		font-family: 'Sohne', sans-serif;
    ${tablet} {
      font-size: 16px;
    }
  }
	
	.hover-underline{
		border-bottom: 1px solid transparent;
		transition: border-color 0.35s;
		&:hover{
			border-bottom: 1px solid var(--black);
		}
	}
`

export const Text = styled(Section)`
	margin-bottom: var(--xxxxl);
`

export const TextEl = styled.div`
	grid-column: span 8;
	${mobile}{
		grid-column: span 12;
	}
	p:first-child{
		text-indent: 16.6666vw;
	}
`

export const Credits = styled.div`
	grid-column: span 4;
	${mobile}{
		grid-column: span 12;
		margin-top: var(--xxl);
	}
	p{
		margin-bottom: 0;
	}
`

export const BlurFilter = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: var(--xxxxl);
	z-index: 9;
	overflow: visible;
	mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1), rgba(0, 0, 0, 1));
	backdrop-filter: blur(10px);
	/* background: linear-gradient(180deg, var(--olive) 0%, var(--olivetrans) 100%); */
	/* background: linear-gradient(180deg, var(--olive) 0%, transparent 100%); */
	transition: background-color 1.3s;
`

const reset = css`

  body {
    margin: 0px;
    min-height: 100%;
		background: var(--olive);
		transition: background-color 1.3s, color 0s;
		color: var(--black);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
		/* transition: color 0.3s; */
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
  }

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
		color: inherit;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 500px white inset !important;
    box-shadow: 0 0 0 500px white inset !important;
  }
`

export default GlobalStyles

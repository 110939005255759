import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet} from '~styles/global'
import * as logoData from '~utils/logoData.json'
import lottie from 'lottie-web'
import useWindow from '~utils/useWindow'
import { useRect } from 'react-use-rect'

const Logo = ({ className, reverse, noSpace }) => {
	const [animation, setAnimation] = useState()
	const [taglineVisible, setTaglineVisible] = useState(true)
	const containerRef = useRef()
	const window = useWindow()

	useEffect(() => {
		if(containerRef?.current){
			const anim = lottie.loadAnimation({
				container: containerRef.current,
				renderer: 'svg',
				loop: false,
				autoplay: false,
				animationData: logoData
			})
			setAnimation(anim)
		}
	}, [containerRef])

	useEffect(() => {
		if(animation){
			const frame = !reverse ? (window?.scrollY / 1) : (document.body.clientHeight - window.innerHeight) - window?.scrollY
			if(frame >= 0 ){
				animation.goToAndStop(frame)
			}
		}

		if(window?.scrollY > 200){
			setTaglineVisible(false)
		} else {
			setTaglineVisible(true)
		}

	}, [window?.scrollY])

	return (
		<>
			<Wrap className={className} css={css`
				top: ${reverse ? 'auto' : '60px'};
				bottom: ${reverse ? '75px' : 'auto'};
				${tablet}{
					top: ${reverse ? 'auto' : '45px'};
					bottom: ${reverse ? '55px' : 'auto'};
				}
				${mobile}{
					bottom: ${reverse ? '106px' : 'auto'};
				}
			`}>
				<div ref={containerRef} css={css`
				path, rect{
					/* fill: currentColor !important; */
				}
			`}/>
				{!reverse &&
				<Tagline css={css`
					transition: opacity 0.35s;
					opacity: ${taglineVisible ? 1 : 0};
				`}>
					Director<br/>Cinematographer
				</Tagline>
				}
			</Wrap>
			{!noSpace && <LogoSpace className={className} css={css`
				height: ${reverse ? 'calc(13vw + 40px)' : 'calc(13vw + 90px)'};
			`} />}
		</>
	)
}

const Wrap = styled.div`
	z-index: 15;
	pointer-events: none;
	position: fixed;
	left: calc(-1.3vw + var(--s));
	right: calc(-1.3vw + var(--s));
`

const LogoSpace = styled.div`
	height: calc(13vw + 80px);
	width: 100%;
	/* background: pink; */
`

const Tagline = styled.h4`
	position: absolute;
	bottom: -10px;
	right: var(--s);
	transform: translateY(100%);
	text-align: right;
	line-height: 1.1;
	${mobile}{
		right: 7px;
	}
`

Logo.propTypes = {
	className: PropTypes.string,
	reverse: PropTypes.bool,
	noSpace: PropTypes.bool,
}

export default Logo
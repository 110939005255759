import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Logo from '~components/Logo'
import Section from '~components/Section'
import useSiteSettings from '~utils/useSiteSettings'
import { useLocation } from '@reach/router'

const Footer = ({ className }) => {
	const location = useLocation()
	const {
		siteTitle,
		phoneNumber,
		email,
		instagramLink,
		vimeoLink,
	} = useSiteSettings()
	return(
		<footer className={className}>
			<Logo reverse css={css`
				transition: opacity 0.5s;
				opacity: ${location.pathname.includes('projects') ? 0 : 1};
			`}/>
			<FooterText>
				<Contact>
					<h4>{siteTitle}</h4>
					<h4>
						<a className="hover-underline" href={`tel:${phoneNumber}`} css={css`
							display: inline-block;
							margin-right: 1em;
						`}>{phoneNumber}</a>
						<a className="hover-underline" href={`mailto:${email}`}>{email}</a>
					</h4>
				</Contact>
				<Social className="h4">
					{instagramLink &&
					<><a className="hover-underline" href={instagramLink} target="_blank" rel="noreferrer">Instagram</a><br/></>
					}
					{vimeoLink &&
					<><a className="hover-underline" href={vimeoLink} target="_blank" rel="noreferrer">Vimeo</a><br/></>
					}
				</Social>
				<Tagline className="h4">
					Director<br/>
					Cinematographer
				</Tagline>
			</FooterText>
		</footer>
    
	)
}

const FooterLogoSpace = styled.div`
	height: 100vh;
	width: 100%;
	/* background: pink; */
`

const FooterText = styled(Section)`
	margin-bottom: var(--s);
	white-space: pre-wrap;
	h4, .h4{
		line-height: 1.2;
	}
`
const Contact = styled.div`
	grid-column: span 6;
	${mobile}{
		grid-column: span 12;
		margin-bottom: var(--s);
	}
`

const Social = styled.div`
	grid-column: span 3;
	${mobile}{
		grid-column: span 6;
	}
`

const Tagline = styled.div`
	grid-column: span 3;
	text-align: right;
	${mobile}{
		grid-column: span 6;
	}
`

Footer.propTypes = {
	className: PropTypes.string,
}

export default Footer

module.exports = {
	siteUrl: 'https://hossein.film',
	previewUrl: 'https://preview-hossein.gtsb.io',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/99dd280d-a7b1-4536-9497-bf39fcadada3',
	netlifySiteName: 'dot-gatsby',
	netlifyAppId: '2b18178c-891f-4706-89c9-b09773c0b0e6',
	netlifyBuildHookId: '6195844fc9b9481f441822b5',
	mailchimpUrl: 'https://work.us20.list-manage.com',
	mailchimpUser: 'b2b9468ba5d766d69fd543e0e',
	mailchimpId: '5226532c72',
	gTag: 'G-HTEQ9FXTLC'
}
import React from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import useSiteSettings from '~utils/useSiteSettings'
import Logo from '~components/Logo'
import { tablet } from '~styles/global'
import { useSiteState } from '~context/siteContext'

const Header = ({ className }) => {
	const { siteTitle } = useSiteSettings()
	const [siteState, setSiteState] = useSiteState()

	return(
		<Wrap className={className}>
			<Fixed css={css`
				transition: opacity 1s;
				opacity: ${siteState.loading ? 0 : 1};
			`}>
				<Title className="h4">
					<Link to="/">
						HOSSEIN&nbsp;&nbsp;&nbsp;KHODABANDEHLOO
					</Link>
				</Title>
				<Nav className="h4">
					<NavLink className="hover-underline" to={'/projects'}>Projects</NavLink>
					<NavLink className="hover-underline" to={'/about'}>About</NavLink>
				</Nav>
			</Fixed>
		</Wrap>
	)
}

const Wrap = styled.header`
  
`

const NavLink = styled(Link)`
	margin-left: var(--s);
`

const Fixed = styled.div`
	position: fixed;
  top: 0;
  right: 0;
	left: 0;
	padding: var(--s);
	z-index: 100;
	display: flex;
	justify-content: space-between;
`

const Nav = styled.nav`
	@media(min-width: 1350px){
		position: absolute;
    left: calc(86.1vw - 40px);
		right: 20px;
		display: flex;
		justify-content: space-between;
	}
`

const Title = styled.h1`
	
`

Header.propTypes = {
	className: PropTypes.string,
}

export default Header
